import React from "react";
import Helmet from "react-helmet";
import { navigate } from "gatsby";

import Layout from "../components/layout";

const Terms = () => {
	React.useEffect(() => {
		navigate("/legal/terms-of-service/");
	}, []);

	return null;

	return (
		<Layout>
			<main>
				<Helmet>
					<title>Terms of Use</title>
				</Helmet>

				<header className="aboveFold">
					<div className="container">
						<h1>Terms of Use</h1>
					</div>
				</header>

				<section className="container container--text">
					<h3 className="">Introduction</h3>
					<p className="u-mb4">
						By using Sidemail (“Service“, “We“, “Us“), you are agreeing to be
						bound by the following terms and conditions (“Terms“, “Terms of
						Use“). These Terms of Use affect your legal rights and obligations.
						If you do not agree to be bound by all of these Terms of Use, do not
						access or use the Service. We reserve the right to update and change
						these Terms of Use without notice.
					</p>

					<h3 className="">Account Terms</h3>
					<ul className="list u-mb4">
						<li>
							You are responsible for maintaining the security of your account.
							Sidemail cannot and will not be liable for any loss or damage from
							your failure to comply with this security obligation.
						</li>
						<li>
							You are responsible for all content posted and activity that
							occurs under your account.
						</li>
						<li>
							You may not use the Service for any illegal purpose or to violate
							any laws in your jurisdiction (including but not limited to
							copyright laws).
						</li>
						<li>
							You must provide your legal information, a valid email address,
							and any other required information requested in order to complete
							the signup process.
						</li>
					</ul>

					<h3 className="">Modifications to the Service and Prices</h3>
					<p className="u-mb4">
						We reserve the right at any time and from time to time to modify or
						discontinue, temporarily or permanently, any part of the Service
						with or without notice. We shall not be liable to you or to any
						third party for any modification, suspension or discontinuance of
						the Service.
					</p>

					<h3 className="">Copyright and Content Ownership</h3>
					<p className="u-mb4">
						We claim no intellectual property rights over the material you
						provide to the Service. All materials uploaded remain yours. We do
						not pre-screen content, but reserves the right in their sole
						discretion to refuse or remove any content that is available via the
						Service. The look of the Service is copyright to Sidemail. All
						rights reserved. You may not duplicate, copy, or reuse any portion
						of the HTML, CSS, JavaScript, or visual design elements without
						express written permission from Us.
					</p>

					<h3 className="">General Conditions</h3>
					<ul className="list u-mb4">
						<li>
							Your use of the Service is at your sole risk. The service is
							provided on an as is and as available basis.
						</li>
						<li>
							Sidemail does not guarantee constant availability of Website
							access and accept no liability for down time or access failure due
							to circumstances beyond its reasonable control (including any
							failure by ISP or system provider).
						</li>
						<li>Technical support is only provided via email.</li>
						<li>
							You understand that the We uses third party vendors and hosting
							partners to provide the necessary hardware, software, networking,
							storage, and related technology required to run the Service.
						</li>
						<li>You must not modify, adapt or hack the Service.</li>
						<li>
							You must not modify another website so as to falsely imply that it
							is associated with the Service.
						</li>
						<li>
							You agree not to reproduce, duplicate, copy, sell, resell or
							exploit any portion of the Service, use of the Service, or access
							to the Service without the express written permission by Us.
						</li>
						<li>
							We may, but have no obligation to, remove content and accounts
							that we determine in our sole discretion are unlawful or violates
							any party’s intellectual property or these Terms of Use.
						</li>
						<li>
							Verbal, physical, written or other abuse (including threats of
							abuse or retribution) of any Service customer or officer will
							result in immediate account termination.
						</li>
						<li>
							We reserve the right to temporarily disable your account if your
							usage significantly exceeds the average usage of other Service
							customers. Of course, we'll reach out to the account owner before
							taking any action except in rare cases where the level of use may
							negatively impact the performance of the Service for other
							customers.
						</li>
						<li>
							We do not warrant that the service will meet your specific
							requirements, the service will be uninterrupted, timely, secure,
							or error-free, the results that may be obtained from the use of
							the service will be accurate or reliable, the quality of any
							products, services, information, or other material purchased or
							obtained by you through the service will meet your expectations,
							and any errors in the Service will be corrected.
						</li>
						<li>
							You expressly understand and agree that We shall not be liable for
							any direct, indirect, incidental, special, consequential or
							exemplary damages, including but not limited to, damages for loss
							of profits, goodwill, use, data or other intangible losses (even
							if the We have been advised of the possibility of such damages),
							resulting from: the use or the inability to use the service; the
							cost of procurement of substitute goods and services resulting
							from any goods, data, information or services purchased or
							obtained or messages received or transactions entered into through
							or from the service; unauthorized access to or alteration of your
							transmissions or data; statements or conduct of any third party on
							the service; or any other matter relating to the service.
						</li>
						<li>
							The failure of the Service to exercise or enforce any right or
							provision of the Terms of Use shall not constitute a waiver of
							such right or provision. The Terms of Use constitutes the entire
							agreement between you and the Service and govern your use of the
							Service, superseding any prior agreements between you and the
							Service (including, but not limited to, any prior versions of the
							Terms of Use).
						</li>
					</ul>

					<h3 className="">Conclusion</h3>
					<p className="u-mb4">
						Any new features that augment or enhance the current Service,
						including the release of new tools and resources, shall be subject
						to the Terms of Service. Continued use of the Service after any such
						changes shall constitute your consent to such changes.
					</p>

					<p className="u-mb4">
						This Terms of Use is effective as of January 1, 2019.
					</p>
				</section>
			</main>
		</Layout>
	);
};

export default Terms;
